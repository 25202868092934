<template>
  <div class="lightbox-user-profile-edit">
    <div class="profile-name">
      <TextInput
        ref="profileFirstName"
        inputLabel="First Name"
        inputName="profileFirstName"
        inputType="text"
        inputClass="dashboard"
        v-model="profile.first_name"
      />

      <TextInput
        ref="profileLastName"
        inputLabel="Last Name"
        inputName="profileLastName"
        inputType="text"
        inputClass="dashboard"
        v-model="profile.last_name"
      />
    </div>

    <div class="profile-address">
      <TextInput
        ref="profileBillingAddress"
        inputLabel="Address"
        inputName="profileBillingAddress"
        inputType="text"
        v-model="profile.billing_address"
      />

      <TextInput
        ref="profileBillingAddressNumber"
        inputLabel="Address Number"
        inputName="profileBillingAddressNumber"
        inputType="text"
        v-model="profile.billing_address_number"
      />
    </div>

    <div class="profile-location">
      <TextInput
        ref="profileBillingCity"
        inputLabel="City"
        inputName="profileBillingCity"
        inputType="text"
        v-model="profile.billing_city"
      />

      <TextInput
        ref="profileBillingCountry"
        inputLabel="Country"
        inputName="profileBillingCountry"
        inputType="text"
        v-model="profile.billing_country"
      />
    </div>

    <div class="profile-company">
      <TextInput
        ref="profileCompanyName"
        inputLabel="Company"
        inputName="profileCompanyName"
        inputType="text"
        v-model="profile.company_name"
      />

      <TextInput
        ref="profileCompanyKvk"
        inputLabel="Company Registration Number"
        inputName="profileCompanyKvk"
        inputType="text"
        v-model="profile.company_kvk"
      />
    </div>

    <TextInput
      ref="profilePhone"
      inputLabel="Phonenumber"
      inputName="profilePhone"
      inputType="text"
      v-model="profile.phonenumber"
    />

    <div class="profile-options">
      <MercalaButton
        v-on:button-event="close()"
        label="Close"
        type="dashboard-cancel"
      />

      <MercalaButton
        v-on:button-event="saveProfile()"
        label="Save"
        type="dashboard-ok"
      />
    </div>
  </div>
</template>

<script>
import AngelaAPI from "@/utils/AngelaAPI";
import { EventBus } from "@/utils/EventBus.js";

export default {
  name: "LightboxUserProfileEdit",
  components: {
    MercalaButton: () => import("@/components/form/MercalaButton"),
    TextInput: () => import("@/components/form/TextInput"),
  },
  data() {
    return {
      profile: {
        first_name: "",
      },
    };
  },
  methods: {
    getProfile() {
      AngelaAPI.getProfile()
        .then((response) => {
          this.profile = response.data;
          this.prefillProfile();
        })
        .catch((error) => {
          // Nothing to handle
        });
    },
    prefillProfile() {
      this.$refs.profileFirstName.setValue(this.profile.first_name);
      this.$refs.profileLastName.setValue(this.profile.last_name);
      this.$refs.profileBillingAddress.setValue(this.profile.billing_address);
      this.$refs.profileBillingAddressNumber.setValue(
        this.profile.billing_address_number
      );
      this.$refs.profileBillingCity.setValue(this.profile.billing_city);
      this.$refs.profileBillingCountry.setValue(this.profile.billing_country);
      this.$refs.profileCompanyName.setValue(this.profile.company_name);
      this.$refs.profileCompanyKvk.setValue(this.profile.company_kvk);
      this.$refs.profilePhone.setValue(this.profile.phonenumber);
    },
    close() {
      this.$emit("close");
    },
    saveProfile() {
      AngelaAPI.updateProfile(this.profile)
        .then((response) => {
          this.$emit("close");
          EventBus.$emit("show-notification", {
            title: "Success!",
            message: "Profile updated successfuly!",
            success: true,
            milliseconds: 3000,
          });
        })
        .catch((error) => {
          EventBus.$emit("show-notification", {
            title: "Oh no...",
            message: "Something went wrong, please try again later.",
            success: false,
            milliseconds: 3000,
          });
        });
    },
  },
  mounted() {
    this.$emit("setLightboxInfo", {
      title: "Edit Profile",
    });

    this.getProfile();
  },
};
</script>

<style lang="scss">
@import "../../styles/lightboxes/ProfileEditLightbox";
</style>
